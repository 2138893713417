import React, {useContext} from 'react';
import clock from '../images/icons/clock-light.svg';
import scissor from '../images/icons/cut-duotone.svg';
import money_bag from '../images/icons/sack-dollar-duotone.svg';
import {LanguageContext} from '../../src/pages/index';
import {languages} from './languages';

const About = () => {
    const language = useContext(LanguageContext);
    const servicesText = languages[language]['about'];

    return (
        <section className='about spacing-bottom' id='about'>
            <div className='about-item'>
                <img className='about-item__image' src={clock} alt='Clock icon'/>
                <h4 className='about-item__title'>{servicesText['review-title-1']}</h4>
                <p className='about-item__text'>{servicesText['review-copy-1']}</p>
            </div>
            <div className='about-item'>
                <img className='about-item__image' src={money_bag} alt='sack of money icon'/>
                <h4 className='about-item__title'>{servicesText['review-title-2']}</h4>
                <p className='about-item__text'>{servicesText['review-copy-2']}</p>
            </div>
            <div className='about-item'>
                <img className='about-item__image' src={scissor} alt='scissor icon'/>
                <h4 className='about-item__title'>{servicesText['review-title-3']}</h4>
                <p className='about-item__text'>{servicesText['review-copy-3']}</p>
            </div>
        </section>
    );
}

export default About;