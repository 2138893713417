import React, {useContext} from 'react';
import {LanguageContext} from '../../src/pages/index';
import {languages} from './languages';

const Hero = () => {
    const language = useContext(LanguageContext);
    const callToActionTxt = languages[language]['callToAction'];

    return (
        <section className='hero spacing-bottom'>
            <div className='hero-overlay-container'>
                <h2 className='hero-text'>Gaby's Unisex Beauty Salon</h2>
                <a href="#contact">
                    <button className='hero-button'>{callToActionTxt}</button>
                </a>
            </div>
        </section>
    );
}

export default Hero;