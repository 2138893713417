import React, {useContext, useState} from 'react';
import {LanguageContext} from '../../src/pages/index';
import {languages} from './languages';
import LanguageToggle from './LanguageToggle';

import hamburger from '../images/icons/menu.svg';
import scissor from '../images/icons/cut-duotone.svg'

const Navbar = (props) => {
    const [state, setState] = useState({
        isMobileNavToggled: false,
    });

    // Show / hide the nav links (only visible on mobile)
    const toggleMobileMenu = () => {
        setState({
            ...state,
            isMobileNavToggled: !state.isMobileNavToggled,
        });
    }

    // automatically close the menu on mobile if a link is clicked so it doesn't stay open and take up space
    const closeMobileMenu = () => {
        setState({
            ...state,
            isMobileNavToggled: false,
        });
    }

    /**
     * A little bit of repeated logic that could maybe be condensed in the html
     * would be a lot more css though, i'm okay with this though since it's not a lot
     */
    return (
        <nav className='nav'>
            <div className='nav__desktop container'>
                <img className='logo' alt='logo' src={scissor}/>
                <NavLinks toggleComponent={ <LanguageToggle onChange={props.switchLanguage}/>}/>            
            </div>

            <div className='nav__mobile container'>
                <img className='logo push-right' alt='logo' src={scissor}/>
                {<LanguageToggle onChange={props.switchLanguage}/>}
                <button className='nav__mobile-hamburger' onClick={toggleMobileMenu}>
                    <img src={hamburger}/>
                </button>
                {state.isMobileNavToggled && <NavLinks handleLinkClick={closeMobileMenu}/>}
            </div>
        </nav>
    );
}

// list of anchor tags. Rendered conditionally on mobile based on the toggle
const NavLinks = (props) => {
    const language = useContext(LanguageContext);
    const navLinksText = languages[language]['navbar'];

    return (
        <ul className='nav__links'>
            {props && props.toggleComponent ? 
                <li>{props.toggleComponent}</li>
                : null
            }
            <li onClick={() => props.handleLinkClick() ?? null}>
                <a className='anchor--white-underline' href='#services'>
                    {navLinksText.services}
                </a>
            </li>
            <li onClick={() => props.handleLinkClick() ?? null}>
                <a className='anchor--white-underline' href='#gallery'>
                    {navLinksText.gallery}
                </a>
            </li>
            <li onClick={() => props.handleLinkClick() ?? null}>
                <a className='anchor--white-underline' href='#about'>
                    {navLinksText.about}
                </a>
            </li>
            <li onClick={() => props.handleLinkClick() ?? null}>
                <a className='anchor--white-underline' href='#contact'>
                    {navLinksText.contact}
                </a>
            </li>
        </ul>
    );
}

export default Navbar;