export const languages = {
    "en": {
        "navbar": {
            "services": "Services",
            "about": "About",
            "gallery": "Gallery",
            "contact": "Contact",
        },
        "about": {
            "review-title-1": "Convenient",
            "review-title-2": "Affordable",
            "review-title-3": "Experienced",
            "review-copy-1": "Gaby's Shop is open 7 days a week. I appreciate that I can just walk in without appointment. It very convenient for my busy schedule.",
            "review-copy-2": "Best deal in town!",
            "review-copy-3": "Love how accomadating they are! Very hardworking family and I love my haircut."
        },
        "services": {
            "basic":" Services",
            "specialty": "Specialties",
            "basics": [
                {
                    "name": 'Adult haircut', "price": 15, "description": "",
                },
                {
                    "name": 'Kid\'s haircut', "price": 14, "description": "",
                },
                {
                    "name": 'Men\'s haircut with shave', "price": 23, "description": "",
                },
                {
                    "name": 'Eyebrow Waxing', "price": 7, "description": "",
                },
            ],
            "specialties": [
                {
                    "name": 'Hair color - root', "price": 40, "description": "",
                },
                {
                    "name": 'Hair color - all',
                    "price": '50+',
                    "description": 'Price may vary depending on length of hair',
                },
                {
                    "name": 'Highlights', "price": '50+', "description": 'Price may vary depending on length of hair',
                },
                {
                    "name": 'Keratin treatment',
                    "price": '80+',
                    "description": 'Straightens hair, reduces frizz, and adds a glossy shine',
                },
                {
                    "name": 'Relaxers / Perm', "price": 50, "description": "",
                },
                {
                    "name": 'Capillary Treatments', "price": 15, "description": "",
                },
            ],
        },
        "contact": {
            "monday": "Monday",
            "saturday": "Saturday",
            "sunday": "Sunday"
        },
        "callToAction": "Come visit us!",
        "footer": "Family owned business serving Nyack for over 20 years!",
    },

    "es": {
        "navbar": {
            "services": "Servicios",
            "about": "Comentarios",
            "gallery": "Fotos",
            "contact": "Contacto",
        },
        "about": {
            "review-title-1": "Conveniente",
            "review-title-2": "Bueno, bonito, y barato",
            "review-title-3": "Expertas",
            "review-copy-1": "Gaby's está abierta los 7 días de la semana. Me gusta que puedo entrar sin cita previa. Es muy conveniente para mi dia ocupdada.",
            "review-copy-2": "La mejor oferta en el pueblo!",
            "review-copy-3": "¡Me encanta lo complacientes que son! Familia muy trabajadora y me encanta mi corte de pelo."
        },
        "services": {
            "basic":" Servicios",
            "specialty": "Especialidades",
            "basics": [
                {
                    "name": 'Corte de pelo para adultos', "description": "", "price": 15,
                },
                {
                    "name": 'Corte de pelo con afeitado', "description": "", "price": 23,
                },
                {
                    "name": 'Corte de pelo para niños', "description": "", "price": 14,
                },
                {
                    "name": 'Depilación de cejas', "description": "", "price": 7,
                },
            ],
            "specialties": [
                {
                    "name": 'Teñido cabello - raiz', "description": "", "price": "40+",
                },
                {
                    "name": 'Teñido cabello - todo', "description": 'El precio puede variar', "price": "50+",
                },
                {
                    "name": 'Reflejos de cabello', "description": 'El precio puede variar', "price": "50+",
                },
                {
                    "name": 'Tratamiento de Keratin',
                    "description": 'Alisa el cabello, reduce el encrespamiento y agrega un brillo brillante',
                    "price": "80+"
                },
                {
                    "name": 'Permanente de cabello', "description": "", "price": 50,
                },
                {
                    "name": 'Tratamiento capilar', "description": "", "price": 15,
                },
            ]
        },
        "callToAction": "¡Visítanos hoy!",
        "footer": "¡Empresa familiar que presta servicios a Nyack hace más de 20 años!",
        "contact": {
            "monday": "Lunes",
            "saturday": "Sabado",
            "sunday": "Domingo"
        }
    }
}