import React, {useContext} from 'react';
import ServicesList from './ServicesList';
import {LanguageContext} from '../../src/pages/index';
import {languages} from './languages';

const Services = () => {
    const language = useContext(LanguageContext);
    const services = languages[language].services;

    return (
        <section className='services' id='services'>
            <ServicesList title={services.basic} services={services.basics}/>
            <ServicesList title={services.specialty} services={services.specialties}/>
        </section>
    )
};

export default Services;