import React from 'react';

const ServicesList = (props) => {
    return (
        <div className='services-list'>
            <h3 className='services-list text--center text--uppercase spacing-bottom'>{props.title}</h3>
            {props.services.map((service, index) => {
                return (
                    <div className='pricing-item spacing-bottom' key={index}>
                        <div className='display--flex'>
                            <h5 className='push-right'>{service.name}</h5>
                            <h5>{service.price}</h5>
                        </div>
                        <p>{service.description}</p>
                    </div>
                )
            })}
        </div>
    )
};

export default ServicesList;