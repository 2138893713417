import React from 'react';
import es_flag from '../images/icons/ecuador.png';
import en_flag from '../images/icons/united-states.png';
import Select from 'react-select';

/**
 * onChange method is passed from index.js -> Navbar -> LangaugeToggle
 *  language is a top level property that impacts the whole app so it's fine that the state isn't stored here
 * 
 */
const LanguageToggle = (props) => {

    // create labels to pass into react-select
    const esOption = 
        <div className='language-toggle__option'>
            <img className='language-toggle__icon' src={es_flag} alt='Ecuador Flag as circle icon to signify spanish'/>
            <span>Spanish</span>
        </div>;

    const enOption = 
        <div className='language-toggle__option'>
            <img className='language-toggle__icon' src={en_flag} alt='American Flag as circle icon to signify english'/>
            <span>English</span>
        </div>;

    const options = [
        { value: 'es', label: esOption},
        { value: 'en', label: enOption},
    ];

    // override some styles ot give us some more clearance
    const selectStyles = {
        valueContainer: (provided) => {
            const height = '30px';
            return {...provided, height};
        }
    }

    return (
        <Select 
            className="language-toggle" 
            options={options}
            styles={selectStyles}
            defaultValue={{ value: 'es', label: esOption}}
            isSearchable={false}
            onChange={props.onChange}
        />
    );
}

export default LanguageToggle;