import React, {useContext} from 'react';
import {LanguageContext} from '../../src/pages/index';
import {languages} from './languages';

const Contact = () => {
    const language = useContext(LanguageContext);
    const daysOfTheWeek = languages[language]['contact'];

    return (
        <section className='contact spacing-bottom' id='contact'>
            <div className='iframe-container'>
                <iframe 
                    title='gaby google maps' 
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3006.9589242253264!2d-73.9243361!3d41.0917441!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2ea102eea7255%3A0x123f08c9260bbe8e!2sGabys%20Unisex%20Beauty%20Salon!5e0!3m2!1sen!2sus!4v1609899890067!5m2!1sen!2sus"
                    scrolling='yes'
                >
                </iframe>
            </div>
            <div className='contact--hours'>
                <h3>Nyack, New York</h3>
                <p>
                    {daysOfTheWeek.monday} &ndash; {daysOfTheWeek.saturday} 9:00 &ndash; 8:00 <br/>
                    {daysOfTheWeek.sunday} 9:00 &ndash; 4:00 <br/>
                    148 Main St, Nyack, <br/>NY 10960
                </p>
            </div>
        </section>
    )

}

export default Contact;