import React, {useState} from 'react'
import Navbar from '../components/Navbar';
import Hero from '../components/Hero';
import About from '../components/About';
import Portfolio from '../components/Portfolio';
import Services from '../components/Services';
import Contact from '../components/Contact';
import Footer from '../components/Footer';

// use context so other components can also pull down the value of "language" and choose the right language for their text
export const LanguageContext = React.createContext();

// default the website to spanish
export default function Home() {
    const [state, setState] = useState({
        language: 'es',
    });

    // we use this in LanguageToggle.js
    const switchLanguage = (event) => {
        setState({
            ...state,
            language: event.value
        });
    };
    const language = state.language;

    return (
        <div>
            <LanguageContext.Provider value={language}>
                <Navbar switchLanguage={switchLanguage}/>
                <Hero/>
                <div className='container'>
                    <Services/>
                    <Portfolio/>
                    <About/>
                    <Contact/>
                </div>
                <Footer/>
            </LanguageContext.Provider>
        </div>
    );
}