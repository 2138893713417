import React from 'react';

import male1 from '../images/gallery-images/male-1.jpg';
import male2 from '../images/gallery-images/male-2.jpg';
import male3 from '../images/gallery-images/male-3.jpg';
import male4 from '../images/gallery-images/male-4.jpg';
import male5 from '../images/gallery-images/male-5.jpg';
import female1 from '../images/gallery-images/female-1.jpg';
import female2 from '../images/gallery-images/female-2.jpg';
import female3 from '../images/gallery-images/female-3.jpg';
import female4 from '../images/gallery-images/female-4.jpg';

// 3rd party libraries
import {Carousel} from 'react-responsive-carousel';
import Gallery from 'react-photo-gallery';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

/**
 * Show the masonry grid on desktop and carousel on tables/phones ( < 768px )
 * Can't use JS to detect screen size at run time due to a gatsby limitation, so we conditionally show/hide with media queries
 * Not ideal, but the site is lightweight enough that we shouldn't run into any trouble
 */
const Portfolio = () => {
    const altTag = 'headshot showcasing a slick haircut';

    return (
        <section className='gallery' id='gallery'>
            <div className='gallery__desktop'>
                <Gallery photos={photos} direction='column'/>
            </div>
            <Carousel showThumbs={false} showArrows={true} infiniteLoop={true} className='gallery__mobile'>
                <img alt={altTag} src={male1}/>
                <img alt={altTag} src={female1}/>
                <img alt={altTag} src={male2}/>
                <img alt={altTag} src={female2}/>
                <img alt={altTag} src={male3}/>
                <img alt={altTag} src={female3}/>
                <img alt={altTag} src={male4}/>
                <img alt={altTag} src={female4}/>
                <img alt={altTag} src={male5}/>
            </Carousel>
        </section>
    )
}

/**
 *  specify the sizes of the pictures for the masonry grid
 *  https://github.com/neptunian/react-photo-gallery
 */
const photos = [
    {
        width: 3,
        height: 4,
        src: male1
    },
    {
        width: 2,
        height: 2,
        src: male2
    },
    {
        width: 3,
        height: 4,
        src: male3
    },
    {
        width: 3,
        height: 4,
        src: male4
    },
    {
        width: 2,
        height: 3,
        src: male5
    },
    {
        width: 3,
        height: 4,
        src: female1
    },
    {
        width: 2,
        height: 3,
        src: female2
    },
    {
        width: 3,
        height: 3,
        src: female3
    },
    {
        width: 4,
        height: 5,
        src: female4
    }
];

export default Portfolio;