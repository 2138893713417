import React, {useContext} from 'react';
import facebook from '../images/icons/facebook-square.svg';
import phone from '../images/icons/phone-square.svg';
import {LanguageContext} from '../../src/pages/index';
import {languages} from './languages';

const Footer = () => {
    const language = useContext(LanguageContext);
    const footerText = languages[language].footer;
    const contact = languages[language].navbar.contact;

    return (
        <footer>
            <div className='footer--about'>
                <h4>Gaby's Unisex Beauty Salon</h4>
                <p>
                    {footerText}
                </p>
            </div>
            <div className='footer--contact'>
                <h4>{contact}</h4>

                <p>
                    <a href='tel:845-358-0788' target='_blank' rel='noreferrer' className='normalized-anchor'>
                        <img alt='phone icon' src={phone} width='25px'/>
                        (845) 358-0788
                    </a>
                </p>
                <p>
                    <a href='https://www.facebook.com/peluqueria.gabys1' target='_blank' rel='noreferrer'
                       className='normalized-anchor'>
                        <img alt='facebook logo' src={facebook} width='25px'/>
                        Facebook
                    </a>
                </p>

            </div>
        </footer>
    )
}

export default Footer;